b
<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">{{ $t('MENU.PURCHASE.TAKEN') }}</h3>
            </div>
            <div class="card-button">
              <v-btn
                :disabled="!selected.length > 0"
                class="mx-2"
                @click="dialog = !dialog"
                small
                color="warning"
              >
                Asosiy vosita +
              </v-btn>
              <v-btn to="/mainassetsdoc/create" small color="primary">
                {{ $t('MENU.PURCHASE.TAKEN') }} +
              </v-btn>
            </div>
          </div>
          <router-view v-on:selected="setSelected($event)"></router-view>
        </div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Asosiy Vosita</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    dense
                    :error-messages="reg_nomerErrors"
                    :success="reg_nomer !== ''"
                    v-model="reg_nomer"
                    label="Registratsiya raqami"
                    outlined
                    @input="$v.reg_nomer.$touch()"
                    @blur="$v.reg_nomer.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :error-messages="oper_dateErrors"
                        dense
                        v-model="oper_date"
                        label="Sana"
                        readonly
                        :success="oper_date !== ''"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @input="$v.oper_date.$touch()"
                        @blur="$v.oper_date.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="oper_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :error-messages="nameErrors"
                    dense
                    v-model="name"
                    :success="name !== ''"
                    label="Nomi"
                    outlined
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :error-messages="inventar_numberErrors"
                    dense
                    :success="inventar_number !== ''"
                    v-model="inventar_number"
                    label="Inventar raqami"
                    outlined
                    @input="$v.inventar_number.$touch()"
                    @blur="$v.inventar_number.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :error-messages="period_usageErrors"
                    dense
                    :success="period_usage !== ''"
                    v-model="period_usage"
                    label="Muddati (Oy)"
                    outlined
                    @input="$v.period_usage.$touch()"
                    @blur="$v.period_usage.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :error-messages="codeErrors"
                    :success="code !== ''"
                    dense
                    v-model="code"
                    label="Kod"
                    v-mask="'####'"
                    counter="4"
                    outlined
                    @input="$v.code.$touch()"
                    @blur="$v.code.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-combobox
                    :error-messages="placement_warehouseErrors"
                    dense
                    :items="allSklads"
                    v-model="placement_warehouse"
                    item-text="sklad_name"
                    :success="
                      placement_warehouse !== '' &&
                      placement_warehouse !== null &&
                      placement_warehouse !== undefined
                    "
                    item-value="id"
                    label="Skladlar"
                    outlined
                    @input="$v.placement_warehouse.$touch()"
                    @blur="$v.placement_warehouse.$touch()"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    disabled
                    dense
                    :value="total_amount | mask"
                    label="Summa"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    :success="comment !== '' && comment !== null"
                    v-model="comment"
                    label="comment"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">Bekor qilish</v-btn>
            <v-btn color="primary" :loading="isLoading" @click="save"
              >Saqlash</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      comment: null,
      menu2: false,
      total_amount: 0,
      reg_nomer: '',
      oper_date: '',
      name: '',
      inventar_number: '',
      period_usage: '',
      code: '',
      placement_warehouse: '',
      singleSelect: false,
      selected: [],
      dialog: false,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Kontragent nomi', value: 'contragent_name' },
        { text: 'Sana', value: 'oper_date' },
        { text: 'Reg raqam', value: 'reg_number' },
        { text: 'Jami', value: 'get_doc_total.total' },
        { text: 'Nsd summa', value: 'get_doc_total.nds_summa' },
        { text: 'Jami nds summa', value: 'get_doc_total.total_withnds_summa' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  validations: {
    oper_date: {
      required
    },
    reg_nomer: {
      required
    },
    inventar_number: {
      required
    },
    period_usage: {
      required
    },
    placement_warehouse: {
      required
    },
    code: {
      required
    },
    name: {
      required
    }
  },
  created() {
    // this.$store.dispatch("getstafflistall");
    this.$store.dispatch('getPaymentSource')
    this.$store.dispatch('getAllSkladList')
    // this.$store.dispatch("mainAssets", "1");
    this.$store.dispatch('mainAssets')
  },
  computed: {
    ddd() {
      return this.$store.getters.test
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    mainAssets() {
      return this.$store.state.requests.mainAssets.results
    },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      console.log('data', data)
      if (data !== undefined && data.length !== undefined) {
        data.forEach((element) => {
          if (element.warehouse_responsible !== null) {
            if (element.responsible_description !== null) {
              element.sklad_name =
                element.name +
                ' | ' +
                element.responsible_description.staffpersonal_description
                  .first_name +
                ' ' +
                element.responsible_description.staffpersonal_description
                  .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        console.log(data)
        return data
      } else return data
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors

      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors

      !this.$v.reg_nomer.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    inventar_numberErrors() {
      const errors = []
      if (!this.$v.inventar_number.$dirty) return errors

      !this.$v.inventar_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    period_usageErrors() {
      const errors = []
      if (!this.$v.period_usage.$dirty) return errors

      !this.$v.period_usage.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    placement_warehouseErrors() {
      const errors = []
      if (!this.$v.placement_warehouse.$dirty) return errors

      !this.$v.placement_warehouse.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.code.$dirty) return errors

      !this.$v.code.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  watch: {
    selected: function (val) {
      console.log(val)
      if (val.length !== 0) {
        this.total_amount = 0
        this.total_amount2 = 0
        val.forEach((x) => {
          if (
            x.get_doc_total.total !== null &&
            x.get_doc_total.total !== undefined &&
            x.get_doc_total.total !== ''
          ) {
            this.total_amount += parseInt(
              x.get_doc_total.total.replace(/[ ,.]/g, ''),
              10
            )
          }
        })
        this.total_amount2 = this.total_amount
      }
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          document: {
            total_amount: this.total_amount,
            import_date: this.oper_date,
            reg_number: this.reg_nomer,
            name: this.name,
            inventar_number: this.inventar_number,
            period_usage: parseInt(this.period_usage, 10),
            code: this.code,
            placement_warehouse: this.placement_warehouse.id,
            comment: this.comment
          },
          main_assets_docs: this.selected
            .filter((x) => x.id == x.id)
            .map((x) => x.id)
        }
        this.$store.dispatch('mainAssetsAdd', data)
        this.selected = []
        this.dialog = false
        this.total_amount = 0
      }
    },
    setSelected(val) {
      this.selected = val
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.PURCHASE.TITLE') },
      { title: this.$t('MENU.PURCHASE.TAKEN') }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
  min-height: 300px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
